<template>
  <v-container fluid class="content-wrapper">
    <v-row row wrap>
      <v-col cols="12">
        <v-skeleton-loader
          v-if="loadingRoomData"
          type="article, list-item-three-line, list-item-three-line, actions"
        ></v-skeleton-loader>
        <div v-else-if="!loadingRoomData" class="card card-shadow pa-5">
          <h3 class="title-sm mb-2 textstyle">
            {{ $t('SigninRoomDetails.editroom') }}
          </h3>
          <v-row class="mt-5">
            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">
                {{ $t('SignupStep2.room_type') }}
              </label>
              <div
                class="dropdown select"
                @click="getfocus('roomtype')"
                @mouseleave="mouseOver('roomtype')"
              >
                <a data-toggle="dropdown" data-display="static" id="roomtype">
                  <v-select
                    v-model="dataroomtype"
                    :items="itemsRoomType"
                    item-text="name"
                    item-value="value"
                    label
                    :placeholder="$t('SignupStep.select_one')"
                    :no-data-text="$t('SignupStep.nodata')"
                    color="pink"
                    solo
                    flat
                    dense
                    single-line
                    hide-details
                    @change="selectRoomTypeFunc"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content
                        @mouseenter="getfocus('roomtype')"
                        @mouseleave="mouseOver('roomtype')"
                      >
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:no-data>
                      <v-btn
                        v-if="!emptyRoomtypes"
                        @click="changeLanguageData"
                        text
                        color="pink"
                        style="width: 100%"
                        >{{ $t('SignupStep.btn_refresh') }}</v-btn
                      >
                      <div
                        role="listbox"
                        tabindex="-1"
                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light"
                        data-v-289cbba2="true"
                        id="list-125"
                      >
                        <div tabindex="-1" class="v-list-item theme--light">
                          <div class="v-list-item__content">
                            <div class="v-list-item__title">
                              {{ $t('Alert.allroomtypesused') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </a>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">
                {{ $t('SignupStep2.room_type_name') }}
              </label>
              <div
                class="dropdown select"
                @click="getfocus('roomname')"
                @mouseleave="mouseOver('roomname')"
              >
                <a data-toggle="dropdown" data-display="static" id="roomname">
                  <v-combobox
                    v-model="dataroomname"
                    :items="itemsRoomName"
                    item-text="room_name"
                    item-value="_id"
                    label
                    hide-no-data
                    :placeholder="$t('SignupStep.select_one')"
                    color="pink"
                    solo
                    flat
                    dense
                    single-line
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <span>{{ checkRoomName(item) }}</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content
                        @mouseenter="getfocus('roomname')"
                        @mouseleave="mouseOver('roomname')"
                      >
                        <v-list-item-title
                          v-html="data.item.room_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:no-data>
                      <v-btn
                        v-if="dataroomtype"
                        @click="initRoomNames"
                        text
                        color="pink"
                        style="width: 100%"
                        >{{ $t('SignupStep.btn_refresh') }}</v-btn
                      >
                      <div
                        v-else
                        role="listbox"
                        tabindex="-1"
                        class="v-list v-select-list v-sheet theme--light v-list--dense theme--light"
                        data-v-289cbba2="true"
                        id="list-125"
                      >
                        <div tabindex="-1" class="v-list-item theme--light">
                          <div class="v-list-item__content">
                            <div class="v-list-item__title">
                              {{ $t('SignupStep.nodata') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-combobox>
                </a>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">
                {{ $t('SignupStep2.room_number') }}
              </label>
              <input
                type="number"
                min="0"
                @keypress="isNumber($event)"
                class="form-control"
                placeholder
                name
                v-model="dataroomnumber"
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">
                {{ $t('SignupStep2.smokingrule') }}
              </label>
              <div
                class="dropdown select"
                @click="getfocus('smokingrule')"
                @mouseleave="mouseOver('smokingrule')"
              >
                <a
                  data-toggle="dropdown"
                  data-display="static"
                  id="smokingrule"
                >
                  <v-select
                    v-model="dataroomsmoke"
                    :items="itemsSmokingRules"
                    item-text="name"
                    item-value="value"
                    label
                    :placeholder="$t('SignupStep.select_one')"
                    :no-data-text="$t('SignupStep.nodata')"
                    color="pink"
                    solo
                    flat
                    dense
                    single-line
                    hide-details
                  >
                    <template v-slot:item="data">
                      <v-list-item-content
                        @mouseenter="getfocus('smokingrule')"
                        @mouseleave="mouseOver('smokingrule')"
                      >
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:no-data>
                      <v-btn
                        @click="initSmokingRules"
                        text
                        color="pink"
                        style="width: 100%"
                        >{{ $t('SignupStep.btn_refresh') }}</v-btn
                      >
                    </template>
                  </v-select>
                </a>
              </div>
            </v-col>

            <v-col
              v-if="dataroomoptions.length"
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="form-group mb-0"
            >
              <v-row row wrap>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <label class="text-body textstyle">
                    {{ $t('SigninRoomDetails.bedtype') }}
                  </label>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <label class="text-body textstyle">
                    {{ $t('SigninRoomDetails.numbedtype') }}
                  </label>
                </v-col>
              </v-row>
              <template v-for="(element, index) in dataroomoptions">
                <div :key="index" class="form-group">
                  <label>
                    <span class="regular">{{ element.option }}</span>
                  </label>

                  <template v-for="(item, i) in element.choosechoices">
                    <v-row :key="item.value">
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        class="pl-md-4 d-flex flex-row align-center"
                      >
                        <div
                          class
                          style="width: 100%"
                          :key="componentKey + 999"
                        >
                          <div
                            class="dropdown select"
                            @click="getfocus('newchoices')"
                            @mouseleave="mouseOver('newchoices')"
                          >
                            <a
                              data-toggle="dropdown"
                              data-display="static"
                              id="newchoices"
                            >
                              <v-select
                                v-model="item._id"
                                :items="
                                  checkChoicesOptions(
                                    element,
                                    item,
                                    item.choices
                                  )
                                "
                                item-text="choice"
                                item-value="_id"
                                label
                                :placeholder="$t('SignupStep2.anotherbed')"
                                :no-data-text="$t('SignupStep.nodata')"
                                color="pink"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                              >
                                <template v-slot:item="data">
                                  <v-list-item-content
                                    @mouseenter="getfocus('newchoices')"
                                    @mouseleave="mouseOver('newchoices')"
                                  >
                                    <v-list-item-title
                                      v-html="data.item.choice"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:no-data>
                                  <v-btn
                                    @click="
                                      checkChoiceItems(
                                        element.choices,
                                        index,
                                        i
                                      )
                                    "
                                    text
                                    color="pink"
                                    style="width: 100%"
                                    >{{ $t('SignupStep.btn_refresh') }}</v-btn
                                  >
                                </template>
                              </v-select>
                            </a>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        class="pl-md-4 d-flex flex-row align-center"
                      >
                        <input
                          type="number"
                          min="1"
                          @keypress="isNumber($event)"
                          class="form-control"
                          placeholder
                          v-model="item.number"
                        />
                        <v-btn
                          icon
                          color="#ff4961"
                          :disabled="
                            index == 0 && element.choosechoices.length == 1
                          "
                          @click="removeChoice(item, index, i)"
                          class="ml-3 hover_numberbtn"
                        >
                          <v-icon x-small>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>

                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-btn
                        depressed
                        color="info"
                        @click="newChoiceFunc(element, index)"
                      >
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        {{ $t('SignupStep2.anotherbed') }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div class="form-group has-button mt-3"></div>
                </div>
              </template>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">{{
                $t('SignupStep2.normal_customer_number')
              }}</label>
              <input
                type="number"
                min="1"
                :max="dataroommax"
                @change="changedatanormalFunc()"
                @keypress="isNumber($event)"
                class="form-control"
                placeholder
                name
                v-model="dataroomnormal"
                style="min-height: 3rem"
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="form-group space-col">
              <label class="text-body textstyle">
                {{ $t('SignupStep2.max_customer_number') }}
              </label>
              <input
                type="number"
                :min="dataroomnormal"
                @keypress="isNumber($event)"
                class="form-control"
                placeholder
                name
                v-model="dataroommax"
                style="min-height: 3rem"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="form-group space-col"
            >
              <label class="text-body textstyle mb-4">{{
                $t('SigninRoomDetails.roomdetails_measure_title')
              }}</label>
              <v-layout row wrap class="ml-1">
                <div
                  class="input-block mr-2"
                  @click="getfocus('dataroommeasure')"
                  id="dataroommeasure"
                >
                  <input
                    type="number"
                    min="1"
                    @keypress="isNumber($event)"
                    class="form-control int2"
                    v-model="dataroommeasure"
                    style="min-height: 3rem"
                  />
                </div>
                <template v-for="item in itemsRoomCapacityUnit">
                  <v-btn
                    height="3rem"
                    :key="item._id"
                    depressed
                    dark
                    tile
                    :outlined="item.value !== dataroommeasureunit"
                    color="#666ee8"
                    @click="changeMeasureUnitFunc(item)"
                  >
                    <span class="textstyle">{{ item.name }}</span>
                  </v-btn>
                </template>
              </v-layout>
            </v-col>
            <v-col cols="6" sm="6" md="2" lg="2">
              <v-btn
                class="mr-3"
                depressed
                dark
                block
                color="#464855"
                style="text-transform: capitalize !important"
                @click="gobackFunc()"
              >
                <span class="textstyle">
                  {{ $t('SigninRoomDetails.btn_back') }}
                </span>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="2" lg="2">
              <v-btn
                depressed
                block
                color="#666ee8"
                style="text-transform: capitalize !important"
                :class="
                  canaddroomtype ? 'activebtn white--text' : 'white--text'
                "
                :disabled="!canaddroomtype"
                @click="dialogConfirmSave = true"
              >
                <span class="textstyle">
                  {{ $t('SigninRoomDetails.btn_save') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfirmSave" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t('Alert.doyouwanttosave') }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            @click="checkAddRoomTypeFunc()"
            depressed
            color="#e83e8c"
            class="pa-3 mr-3 white--text"
            >{{ $t('SigninGeneral.btn_save') }}</v-btn
          >
          <v-btn
            @click="dialogConfirmSave = false"
            color="#e83e8c"
            class="pa-3"
            outlined
            >{{ $t('SigninGeneral.btn_cancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from '@/EventBus'
import swal from 'sweetalert'

export default {
  name: 'SigninRoomDetailsUpdateScreen',
  metaInfo: {
    title: 'Update Room',
    titleTemplate: '%s | Aigent',
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData()

    if (changeValue) {
      const answer = window.confirm(this.$t('Alert.needtogootherroute'))
      if (answer) {
        next()
      } else {
        // this.changeLanguageData();
        next(false)
      }
    } else next()
  },
  data: () => ({
    itemsAllRoomData: [],
    itemsSmokingRules: [],
    itemsRoomType: [],
    itemsRoomName: [],
    itemsRoomCapacityUnit: [],
    userToken: '',
    propertyID: '',
    datafileupload: null,
    url_datafileupload: null,
    datafileuploadnum: 0,
    datafileuploadname: '',
    fileSelecting: false,
    dataroomtype: '',
    dataroomname: '',
    dataroomnumber: '',
    dataroomsmoke: '',
    dataroomoptions: [],
    dataroomnormal: 2,
    dataroommax: 2,
    dataroommeasure: '',
    dataroommeasureunit: '',
    canaddroomtype: false,
    emptyRoomtypes: true,
    componentKey: 0,
    roomID: '',
    selectoldroomname: '',
    selectoldroomvalue: '',
    roomIdOldData: null,
    value_element: '',
    dialogConfirmSave: false,
    loadingRoomData: true,
    oldData: {},
    saveSuccess: false,
  }),
  created() {
    const self = this
    self.userToken = self.$route.params.userToken
    self.propertyID = self.$route.params.propertyId
    self.roomID = self.$route.params.roomId
    if (self.userToken && self.propertyID) {
      self.changeLanguageData()
    } else {
      self.gobackFunc()
    }
    self.changeLanguageData()
  },
  mounted() {
    EventBus.$on('refreshRightPhoto', this.changeLanguageData)
    this.colorHover()
  },
  watch: {
    dataroomtype() {
      this.checkcanadd()
    },
    dataroomname() {
      this.checkcanadd()
    },
    dataroomnumber() {
      this.checkcanadd()
    },
    dataroomsmoke() {
      this.checkcanadd()
    },
    dataroomoptions() {
      this.checkcanadd()
    },
    dataroomnormal() {
      this.changedatanormalFunc()
      this.checkcanadd()
    },
    dataroommax() {
      this.checkcanadd()
    },
    dataroommeasure() {
      this.checkcanadd()
    },
    dataroommeasureunit() {
      this.checkcanadd()
    },
    'self.roomIdOldData.selectoldroomname'() {
      if (!this.dataroomname) this.mappingDataFunc()
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector('.hover_btn')
      if (button) {
        button.addEventListener('mousemove', (e) => {
          const { x, y } = button.getBoundingClientRect()
          button.style.setProperty('--x', e.clientX - x)
          button.style.setProperty('--y', e.clientY - y)
        })
      }
    },
    getfocus(val) {
      var element = document.getElementById(val)
      if (this.value_element === '') {
        element.classList.add('border-pink-focus')
        this.value_element = val
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove('border-pink-focus')
        element.classList.add('border-pink-focus')
        this.value_element = val
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val)
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove('border-pink-focus')
        element.classList.remove('border-pink-focus')
        this.value_element = ''
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changedatanormalFunc() {
      const self = this
      if (self.dataroomnormal > self.dataroommax) {
        self.dataroommax = self.dataroomnormal
      }
    },
    checkcanadd() {
      const self = this
      var numofchoices = 0
      if (self?.dataroomoptions?.length) {
        self?.dataroomoptions.map((ele) => {
          if (ele?.choosechoices?.length) numofchoices = numofchoices + 1
        })
      }
      if (
        !self?.dataroomtype ||
        !self?.dataroomname ||
        !self?.dataroomsmoke ||
        !self?.dataroomnumber ||
        self?.dataroomnumber == 0 ||
        !self?.dataroomnormal ||
        self?.dataroomnormal == 0 ||
        !self?.dataroommax ||
        self?.dataroommax == 0 ||
        !self?.dataroommeasure | (self?.dataroommeasure == 0) ||
        !self?.dataroommeasureunit
      ) {
        self.canaddroomtype = false
      } else if (self?.dataroomoptions?.length == 0) self.canaddroomtype = false
      else if (self?.dataroomnormal > self?.dataroommax)
        self.canaddroomtype = false
      else {
        if (numofchoices) self.canaddroomtype = true
      }
    },
    gobackFunc() {
      const linkItems = {
        link: `room/details/${self.userToken}/${self.propertyID}`,
        name: 'SigninRoomDetailsScreen',
        params: { userToken: self.userToken, propertyId: self.propertyID },
      }
      EventBus.$emit('changePathname', linkItems)
    },
    changeLanguageData() {
      const self = this
      self.loadingRoomData = true
      self.initRoomTypes()
      self.initSmokingRules()
      self.initMeasures()
      setTimeout(() => {
        self.loadingRoomData = false
      }, 5000)
    },
    async initRoomData() {
      const self = this
      var temp = {}
      var items = []
      var tempItems = []

      var tempRoomOptions = []
      var tempRoomOldOptions = []
      var tempRoomChoices = []
      EventBus.$emit('loadingtillend')
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem('Token') ||
                self?.$store?.state?.resSignUpData?.access_token,
              'X-Language-Code':
                localStorage?.getItem('language') ||
                self?.$store?.state?.language,
            },
          }
        )
        if (res.status == 200) {
          temp = res?.data?.result[0]
          if (res?.data?.total) {
            items = temp.rooms
            items.map((el) => {
              tempRoomOptions = []
              el?.options?.map((ele) => {
                tempRoomChoices = []
                ele?.choices?.map((element) => {
                  if (ele?.choices?.length) {
                    tempRoomChoices.push({
                      choice: element?.choice?._id || '',
                      number: element?.number || 1,
                    })
                  }
                })
                tempRoomOptions.push({
                  option: ele?.option?._id,
                  choices: tempRoomChoices,
                })
                if (self.roomID == el._id) {
                  tempRoomOldOptions.push({
                    _id: ele?.option?._id,
                    option: ele?.option?.value,
                    choices: tempRoomChoices,
                  })
                }
              })

              tempItems.push({
                _id: el?._id,
                room_type:
                  typeof el?.room_type === 'object'
                    ? el?.room_type?._id || ''
                    : el?.room_type,
                room_name:
                  typeof el?.room_name === 'object'
                    ? el?.room_name?._id || ''
                    : el?.room_name,
                selectoldroomname:
                  typeof el?.room_name === 'object'
                    ? el?.room_name?.value || ''
                    : el?.room_name,
                selectoldroomvalue:
                  typeof el?.room_name === 'object'
                    ? el?.room_name?._id || ''
                    : el?.room_name,
                room_number: Number(el?.room_number) || 0,
                smoke: el?.smoke || '',
                options: tempRoomOptions,
                normal: Number(el?.customer?.normal) || 2,
                max: Number(el?.customer?.max) || 2,
                room_size: Number(el?.room_size?.size) || 0,
                measure_unit: el?.room_size?.measure?._id || '',
              })

              if (el._id == self.roomID) {
                self.roomIdOldData = {
                  _id: el?._id,
                  room_type:
                    typeof el?.room_type === 'object'
                      ? el?.room_type?._id || ''
                      : el?.room_type,
                  room_name:
                    typeof el?.room_name === 'object'
                      ? el?.room_name?._id || ''
                      : el?.room_name,
                  selectoldroomname:
                    typeof el?.room_name === 'object'
                      ? el?.room_name?.value || ''
                      : el?.room_name,
                  selectoldroomvalue:
                    typeof el?.room_name === 'object'
                      ? el?.room_name?._id || ''
                      : el?.room_name,
                  room_number: Number(el?.room_number) || 0,
                  smoke: el?.smoke || '',
                  options: tempRoomOldOptions,
                  normal: Number(el?.customer?.normal) || 2,
                  max: Number(el?.customer?.max) || 2,
                  room_size: Number(el?.room_size?.size) || 0,
                  measure_unit: el?.room_size?.measure?._id || '',
                }
              }
            })
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message)
      } finally {
        self.itemsAllRoomData = tempItems

        if (self?.roomIdOldData?._id) {
          self.initRoomNames()
          self.mappingDataFunc()
        } else self.loadingRoomData = false
      }
    },
    mappingDataFunc() {
      const self = this
      // self.roomID
      const temp = self.itemsAllRoomData.find((p) => p._id == self.roomID)
      EventBus.$emit('loadingtillend')
      self.dataroomtype = temp?.room_type || ''
      //   self.dataroomname = temp?.room_name || "";
      self.selectoldroomname = temp?.selectoldroomname || ''
      self.selectoldroomvalue = temp?.selectoldroomvalue || ''
      self.dataroomnumber = temp?.room_number || ''
      self.dataroomsmoke = temp?.smoke || ''
      self.dataroomnormal = temp?.normal || 2
      self.dataroommax = temp?.max || 2
      self.dataroommeasure = temp?.room_size || ''
      self.dataroommeasureunit = temp?.measure_unit || ''

      self.oldData = temp

      if (self?.dataroomtype) {
        self.selectRoomTypeFunc()
      }
      self.checkcanadd()
    },
    initSmokingRules() {
      const self = this
      const temp = [
        {
          _id: 0,
          name: self.$t('SignupStep2.smokinrule1'),
          value: 'Smoking',
        },
        {
          _id: 1,
          name: self.$t('SignupStep2.smokinrule2'),
          value: 'Non smoking',
        },
        {
          _id: 2,
          name: self.$t('SignupStep2.smokinrule3'),
          value:
            'I have  both smoking and non-smoking options for this room type',
        },
      ]
      self.itemsSmokingRules = temp
    },
    async initRoomTypes() {
      const self = this
      var temp = []
      var item = []
      // self.itemsRoomType = []
      // self.itemsRoomName = []
      EventBus.$emit('loadingtillend')
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/room_type?lang=${
              localStorage.getItem('language') || self.$store.state.language
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem('Token') ||
                self?.$store?.state?.resSignUpData?.access_token,
              'X-Language-Code':
                localStorage?.getItem('language') ||
                self?.$store?.state?.language,
            },
          }
        )
        if (res.status == 200) {
          item = res.data.result
          item.map((el) => {
            temp.push({
              ...el,
              name: el.value,
              value: el._id,
            })
          })
          self.itemsRoomType = temp
        }
      } catch (error) {
        console.log(error?.response?.data?.message)
      } finally {
        EventBus.$emit('endloading')

        self.initRoomData()
      }
    },
    async initRoomNamesByType() {
      const self = this
      var temp = []
      var item = []
      self.itemsRoomName = []
      EventBus.$emit('loadingtillend')
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/room_name?room_type=${self.dataroomtype}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem('Token') ||
                self?.$store?.state?.resSignUpData?.access_token,
              'X-Language-Code':
                localStorage?.getItem('language') ||
                self?.$store?.state?.language,
            },
          }
        )
        if (res.status == 200) {
          item = res.data.result
          item.map((el) => {
            temp.push({
              ...el,
              name: el.value,
              value: el._id,
              room_name: el.value,
            })
          })
        }
      } catch (error) {
        console.log(error?.response?.data?.message)
      } finally {
        EventBus.$emit('endloading')
        self.itemsRoomName = temp
      }
    },
    async initMeasures() {
      const self = this
      var temp = []
      EventBus.$emit('loadingtillend')
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/measure?lang=${
              localStorage.getItem('language') || self.$store.state.language
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem('Token') ||
                self?.$store?.state?.resSignUpData?.access_token,
              'X-Language-Code':
                localStorage?.getItem('language') ||
                self?.$store?.state?.language,
            },
          }
        )
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push({
              _id: el._id,
              name: el.value,
              value: el._id,
            })
          })
        }
        self.itemsRoomCapacityUnit = temp
        self.dataroommeasureunit = temp[0].value
      } catch (error) {
        console.log(error?.response?.data?.message)
      }
    },
    selectRoomTypeFunc() {
      const self = this
      self.loadingRoomData = true
      EventBus.$emit('loadingtillend')
      self.initRoomNamesByType()

      var tempName = []
      if (self.dataroomtype !== self?.roomIdOldData?.room_type)
        self.dataroomname = ''
      self.itemsRoomName = []

      var roomtypeSelect = self.itemsRoomType.find(
        (p) => p._id == self.dataroomtype
      )

      var temp = roomtypeSelect?.room_name || []
      temp.map((el) => {
        var selecttyped = self.itemsAllRoomData.find(
          (p) => p.room_name == el._id
        )
        if (selecttyped == undefined) {
          tempName.push(el)
        }
      })

      self.itemsRoomName = tempName

      var defaultTempChoice = []
      if (self.dataroomtype == self?.roomIdOldData?.room_type) {
        var tempOptions = []
        var tempOption = []
        var tempChoice = []
        if (self?.roomIdOldData?.options?.length) {
          self?.roomIdOldData?.options?.map((el) => {
            defaultTempChoice = []
            tempOption = []
            tempChoice = []
            tempOption = roomtypeSelect?.options?.find((p) => p._id == el._id)
            if (!tempOption) tempOption = roomtypeSelect?.options[0]?.choices
            if (el?.choices?.length) {
              el?.choices?.map((ele) => {
                tempChoice = tempOption.choices.find((p) => p._id == ele.choice)

                if (tempChoice?._id)
                  defaultTempChoice.push({
                    _id: tempChoice._id,
                    choice: tempChoice.choice || '',
                    value: tempChoice._id || '',
                    number: ele.number,
                    hover: false,
                    choices: tempOption,
                  })
              })
            } else {
              defaultTempChoice.push({
                _id: roomtypeSelect?.options[0]?.choices[0]._id,
                choice: roomtypeSelect?.options[0]?.choices[0].choice,
                value: roomtypeSelect?.options[0]?.choices[0]._id,
                number: 1,
                hover: false,
                choices: roomtypeSelect?.options[0]?.choices,
              })
            }
            tempOptions.push({
              _id: el?._id,
              option: el?.option,
              choices: tempOption,
              choosechoices: defaultTempChoice,
            })
          })
          self.dataroomoptions = tempOptions
        }
        if (self?.itemsRoomName?.length) {
          self.dataroomname =
            typeof self?.roomIdOldData?.room_name === 'object'
              ? self?.roomIdOldData?.room_name?.value || ''
              : self?.roomIdOldData?.room_name
          self.checkRoomName()
        }
      } else {
        self.dataroomoptions = roomtypeSelect.options
        self.dataroomnormal = 2
        self.dataroommax = 2
        self.dataroommeasure = ''
        self.dataroommeasureunit = self?.itemsRoomCapacityUnit[0]?.value || ''
        defaultTempChoice.push({
          _id: roomtypeSelect?.options[0]?.choices[0]._id,
          choice: roomtypeSelect?.options[0]?.choices[0].choice,
          value: roomtypeSelect?.options[0]?.choices[0]._id,
          number: 1,
          hover: false,
          choices: roomtypeSelect?.options[0]?.choices,
        })
        self.dataroomoptions[0].choosechoices = defaultTempChoice
      }
      setTimeout(() => {
        EventBus.$emit('endloading')
        self.loadingRoomData = false
      }, 3000)
    },
    initRoomNames() {
      const self = this
      var temp = []
      var tempName = []
      self.itemsRoomName = []
      var roomtypeSelect = self.itemsRoomType.find(
        (p) => p._id == self.dataroomtype
      )

      temp = roomtypeSelect?.room_name || []
      temp.map((el) => {
        var selecttyped = self.itemsAllRoomData.find(
          (p) => p.room_name == el._id
        )
        if (selecttyped == undefined)
          tempName.push({
            _id: el._id,
            name: el.room_name,
            value: el._id,
          })
      })

      self.itemsRoomName = tempName
    },
    getMeasureUnitName(item) {
      const self = this
      const temp = self?.itemsRoomCapacityUnit?.find((p) => p.value == item)
      return temp?.name || ''
    },
    changeMeasureUnitFunc(item) {
      const self = this
      self.dataroommeasureunit = item.value
    },
    checkAddRoomTypeFunc() {
      const self = this
      self.dialogConfirmSave = false
      EventBus.$emit('loadingtillend')
      var tempRoomOptions = []
      var tempRoomChoices = []
      self?.dataroomoptions?.map((ele) => {
        tempRoomChoices = []
        ele?.choosechoices?.map((el) => {
          if (ele?.choosechoices?.length) {
            if ((el?._id || el?.value) && el.number > 0) {
              tempRoomChoices.push({
                choice: el?._id || el?.value,
                number: el.number,
              })
            }
          }
        })
        tempRoomOptions.push({
          option: ele._id,
          choices: tempRoomChoices,
        })
      })

      const obj = {
        room_type: self?.dataroomtype || '',
        room_name:
          typeof self?.dataroomname === 'object'
            ? self?.dataroomname?.value || ''
            : self?.dataroomname,
        room_number: Number(self?.dataroomnumber) || 0,
        smoke: self?.dataroomsmoke || '',
        options: tempRoomOptions,
        customer: {
          normal: Number(self?.dataroomnormal) || 0,
          max: Number(self?.dataroommax) || 0,
        },
        room_size: {
          size: Number(self?.dataroommeasure) || 0,
          measure: self?.dataroommeasureunit || '',
        },
      }

      if (
        !obj.room_type ||
        !obj.room_name ||
        !obj.smoke ||
        !obj.room_number ||
        obj.room_number === 0 ||
        !obj.customer.normal ||
        obj.customer.normal === 0 ||
        !obj.customer.max ||
        obj.customer.max === 0 ||
        obj?.customer.normal > obj?.customer.max ||
        !obj.room_size.size ||
        obj.room_size.size === 0 ||
        !obj.room_size.measure
      ) {
        EventBus.$emit('endloading')
        if (!obj.room_type) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t('SignupStep2.room_type')}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.room_name) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t(
              'SignupStep2.room_type_name'
            )}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.smoke) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t(
              'SignupStep2.smokingrule'
            )}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.room_number || obj.room_number === 0) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t(
              'SignupStep2.room_number'
            )}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.customer.normal || obj.customer.normal === 0) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t(
              'SignupStep2.normal_customer_number'
            )}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.customer.max || obj.customer.max === 0) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t(
              'SignupStep2.max_customer_number'
            )}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (obj.customer.normal > obj.customer.max) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('SignupStep2.max_customer_number')}${self.$t(
              'SigninPolicy.less_checkin'
            )}${self.$t('SignupStep2.normal_customer_number')}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.room_size.size || obj.room_size.size === 0) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t('SignupStep2.room_size')}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else if (!obj.room_size.measure) {
          swal(
            self.$t('Alert.warn_title'),
            `${self.$t('Alert.pleasefill')}${self.$t('SignupStep2.room_unit')}`,
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        } else {
          swal(
            self.$t('Alert.warn_title'),
            self.$t('Alert.fillinfo'),
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        }
      } else {
        self.canaddroomtype = false
        self.updateRoomTypeFunc(obj)
      }
    },
    async updateRoomTypeFunc(item) {
      const self = this
      const obj = item
      const linkItems = {
        link: `room/details/${self.userToken}/${self.propertyID}`,
        name: 'SigninRoomDetailsScreen',
        params: { userToken: self.userToken, propertyId: self.propertyID },
      }

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/room?room_id=${self.roomID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem('Token') ||
                self?.$store?.state?.resSignUpData?.access_token,
              'X-Language-Code':
                localStorage?.getItem('language') ||
                self?.$store?.state?.language,
            },
          }
        )

        if (res.status == 200) {
          self.saveSuccess = true
          EventBus.$emit('endloading')

          swal(
            self.$t('Alert.success_title'),
            self.$t('Alert.save_success'),
            self.$t('Alert.success_label'),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => {
            if (self.url_datafileupload) {
              self.uploadFileFunc(linkItems)
            } else {
              self.canaddroomtype = true
              EventBus.$emit('changePathname', linkItems)
            }
          })
        } else {
          EventBus.$emit('endloading')
          self.canaddroomtype = true
          swal(
            self.$t('Alert.warn_title'),
            self.$t('Alert.tryagain'),
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          )
        }
      } catch (error) {
        EventBus.$emit('endloading')
        self.canaddroomtype = true
        swal(
          self.$t('Alert.warn_title'),
          error?.response?.data?.message || self.$t('Alert.tryagain'),
          self.$t('Alert.warn_label'),
          {
            button: false,
            timer: 3000,
          }
        )
        console.log(error?.response?.data?.message)
      }
    },
    async uploadFileFunc(linkItems) {
      const self = this
      if (self.roomID) {
        var key = self.roomID
        var formData = new FormData()
        formData.append(`${key}`, self.datafileupload.target.files[0])
        EventBus.$emit('loadingtillend')

        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API +
              `/property/picture/cover?property_id=${self.propertyID}`,
            formData,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem('Token') ||
                  self?.$store?.state?.resSignUpData?.access_token,
                'X-Language-Code':
                  localStorage?.getItem('language') ||
                  self?.$store?.state?.language,
              },
            }
          )
          if (res.status == 200) {
            self.canaddroomtype = true
            EventBus.$emit('changePathname', linkItems)
          } else {
            EventBus.$emit('endloading')
            swal(
              self.$t('Alert.warn_title'),
              self.$t('Alert.tryagain'),
              self.$t('Alert.warn_label'),
              {
                button: false,
                timer: 3000,
              }
            )
          }
        } catch (error) {
          EventBus.$emit('endloading')
          self.canaddroomtype = true
          console.log(error?.response?.data?.message || error)
          swal(
            'Warning',
            error?.response?.data?.message || 'Please try again',
            'warning',
            {
              button: false,
              timer: 3000,
            }
          )
        }
      } else {
        self.canaddroomtype = true
        EventBus.$emit('changePathname', linkItems)
      }
    },
    checkChoiceItems(data, index) {
      const self = this
      var temp = []
      data?.map((el) => {
        const newData = self?.dataroomoptions[index]?.choosechoices?.find(
          (p) => p.value == el._id
        )
        if (!newData) {
          temp.push(el)
        }
      })

      return temp
    },
    newChoiceFunc(element, index) {
      const self = this
      var defaultTempChoice = {
        _id: '',
        choice: '',
        value: '',
        number: 1,
        hover: false,
        choices: element.choices,
      }
      if (element?.choosechoices?.length)
        element.choosechoices.push(defaultTempChoice)
      else self.dataroomoptions[index].choosechoices = [defaultTempChoice]
      self.forceRerender()
    },
    addNewChoice(choice, index) {
      const self = this
      var temp = []
      temp = self.dataroomoptions[index].choosechoices || []
      var indexOfData =
        self?.dataroomoptions[index]?.choosechoices?.findIndex(
          (p) => p.value == choice
        ) !== undefined
          ? self?.dataroomoptions[index]?.choosechoices?.findIndex(
              (p) => p.value == choice
            )
          : -1
      var tempChoice = self?.dataroomoptions[index]?.choices?.find(
        (p) => p._id == choice
      )

      if (indexOfData > -1) {
        swal(
          self.$t('Alert.warn_title'),
          self.$t('Alert.alreadyhavedata'),
          self.$t('Alert.warn_label'),
          {
            button: false,
            timer: 3000,
          }
        )
      } else {
        EventBus.$emit('loadingtillend')
        temp.push({
          choice: tempChoice.choice,
          value: tempChoice._id,
          number: 1,
          hover: false,
        })
        self.dataroomoptions[index].choosechoices = temp
        setTimeout(() => {
          self.dataroomoptions[index].choice = ''
          EventBus.$emit('endloading')
        }, 200)
      }
    },
    removeChoice(item, optionIndex, i) {
      const self = this
      self.componentKey = 0
      EventBus.$emit('loadingtillend')
      self?.dataroomoptions[optionIndex]?.choosechoices?.splice(i, 1)
      setTimeout(() => {
        EventBus.$emit('endloading')
        self.forceRerender()
        self.checkcanadd()
      }, 200)
    },
    forceRerender() {
      this.componentKey += 1
    },
    checkRoomName(item) {
      const self = this
      const tempRoomName = self?.itemsRoomName?.find((p) => p._id == item)
      if (typeof tempRoomName == 'object') {
        return tempRoomName?.room_name
      } else if (typeof item == 'object') {
        return item?.room_name
      } else {
        return item || self?.dataroomname
      }
    },
    onButtonClick() {
      this.fileSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.fileSelecting = false
        },
        { once: true }
      )

      this.$refs.datafileuploadid.click()
    },
    handleFileChange(file) {
      const self = this
      document.body.onfocus = self.checkIt()
      if (file !== 'undefined') self.datafileupload = file
      if (self.datafileupload) {
        self.handleFileChangeName()
        const fileurl = file.target.files[0]
        self.url_datafileupload = URL.createObjectURL(fileurl)
      }
    },
    checkIt() {
      const self = this
      var theFile = document.getElementById('datafileuploadid')
      if (theFile.value.length == 0) {
        self.datafileupload = null
        this.datafileuploadname = ''
      }
      document.body.onfocus = null
      self.handleFileChangeName()
    },
    handleFileChangeName() {
      this.datafileupload
        ? (this.datafileuploadname = this.datafileupload.target.files[0].name)
        : (this.datafileuploadname = this.$t('SigninRoomDetails.uploadphoto'))
    },
    checkChoicesOptions(element, item, choices) {
      var tmpChoices = []
      if (choices?.choices) {
        choices?.choices.filter((el) => {
          const findDupChoosen = element?.choosechoices?.find(
            (p) => p._id == el?._id
          )
          if (!findDupChoosen || el?._id == item?._id) tmpChoices.push(el)
        })
      } else {
        choices?.filter((el) => {
          const findDupChoosen = element?.choosechoices?.find(
            (p) => p._id == el?._id
          )
          if (!findDupChoosen || el?._id == item?._id) tmpChoices.push(el)
        })
      }
      return tmpChoices
    },
    checkChangeData() {
      const self = this
      var cantleavethispage = false

      if (self?.saveSuccess) {
        cantleavethispage = false
      } else if (
        self.dataroomtype !== self.oldData.room_type ||
        self.dataroomname !== self.oldData.selectoldroomvalue ||
        self.dataroomsmoke !== self.oldData.smoke ||
        self.dataroomnumber !== self.oldData.room_number ||
        self.dataroomnormal !== self.oldData.normal ||
        self.dataroommax !== self.oldData.max ||
        self.dataroommeasure !== self.oldData.room_size ||
        self.dataroommeasureunit !== self.oldData.measure_unit
      ) {
        cantleavethispage = true
      } else cantleavethispage = false
      return cantleavethispage
    },
  },
}
</script>

<style scoped>
@import '../../../../styles/bootstrap/global.css';
@import '../../../../styles/signin/roomdetails.css';
@import '../../../../styles/signin/setting.css';
@import '../../../../styles/signin/holiday.css';
@import '../../../../styles/signin/rateplan.css';
@import '../../../../styles/signin/promotion.css';
</style>

<style lang="scss" scoped>
.border-pink-focus {
  border-color: var(--pink) !important;
}

.field-icon {
  float: right;
  margin-left: -1.563rem;
  margin-top: -2.13rem;
  margin-right: 1.25rem;
  position: relative;
  z-index: 999;
}

.btn-measureunit-active {
  color: #fff;
  background-color: var(--info);
}

.roomdetails-img-picker {
  width: 25rem;
  height: 25rem;
  background-color: #e6e6e6;
  object-fit: cover;
}
</style>
